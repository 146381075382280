<template><router-view /></template>
<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useStore } from 'vuex'
import { useErrorNotify } from './util'
export default defineComponent({
  setup() {
    const errorNotify = useErrorNotify()
    const store = useStore()
    const errorResponse = computed(() => store.state.errorResponse)
    watch(errorResponse, (error) => {
      if (error) {
        const detail = error.response.data.detail
        if (typeof detail === 'string') {
          errorNotify({ ...new Error(), name: detail })
        } else if (Array.isArray(detail)) {
          detail.forEach((it: any) => {
            errorNotify({ ...new Error(), name: it.msg })
          })
        } else {
          errorNotify({ ...new Error(), name: '不明なエラーです' })
        }
      }
    })
    return {}
  },
})
</script>
