import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    component: () => import('@/views/auth/SignIn.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/',
    component: () => import('@/views/_layout.vue'),
    children: [
      { path: '', component: () => import('@/views/Index.vue'), meta: { title: 'コミュニティ' } },
      {
        path: '/invitations',
        component: () => import('@/views/Invitations.vue'),
        meta: { title: '招待一覧' },
      },
      {
        path: '/invitations/:id',
        component: () => import('@/views/Invitation.vue'),
        meta: { title: '招待' },
      },
      { path: 'profile', component: () => import('@/views/Profile.vue'), meta: { title: 'プロフィール' } },
      { path: 'notification', component: () => import('@/views/Notification.vue'), meta: { title: 'お知らせ' } },
      { path: 'buy-me-a-coffee', component: () => import('@/views/BuyMeACoffee.vue'), meta: { title: 'コーヒーを奢る' } },
      { path: 'contact', component: () => import('@/views/Contact.vue'), meta: { title: 'お問い合わせ' } },
      {
        path: 'community',
        children: [
          { path: 'create', component: () => import('@/views/CommunityCreate.vue') },
          { path: 'join', component: () => import('@/views/CommunityJoin.vue') },
          { path: 'migration', component: () => import('@/views/CommunityMigration.vue') },
          {
            path: ':id',
            component: () => import('@/views/community/_layout.vue'),
            children: [
              { path: '', component: () => import('@/views/community/Index.vue') },
              { path: 'game/:uuid', component: () => import('@/views/community/Game.vue') },
              { path: 'member', component: () => import('@/views/community/Member.vue') },
              { path: 'settings', component: () => import('@/views/community/Settings.vue') },
            ],
          },
        ],
      },
      { path: 'admin', component: () => import('@/views/admin/_layout.vue'),children: [
        { path: '', redirect: '/admin/users' },
        { path: 'users', component: () => import('@/views/admin/Users.vue'), meta: { title: 'ユーザー' } },
        { path: 'communities', component: () => import('@/views/admin/Communities.vue'), meta: { title: 'コミュニティ' } },
        { path: 'reply', component: () => import('@/views/admin/Reply.vue'), meta: { title: 'お問い合わせ' } },
        { path: 'batch', component: () => import('@/views/admin/Batch.vue'), meta: { title: 'バッチ' } },
      ] },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  // フロントAPPバージョンのチェック（アップデートがあれば強制リロード）
  // if (process.env.NODE_ENV === 'production') await store.dispatch('version', to.path)

  const isPublic = to.matched.some(record => record.meta.isPublic)
  if (!isPublic) {
    await store.dispatch('authenticate')
    if (!store.getters.isAuthenticated) {
      next({ path: '/auth', query: { redirect: to.fullPath } })
      return
    }
  }
  next()
})

export default router
