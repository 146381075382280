import api from '@/api'
import { GameField } from '@/models/gameField'
import { User, UserInput } from '@/models/user'


export const createUser = async (params: UserInput): Promise<User | null> => {
  return await api
    .post('users', params)
    .then((resp: any) => (resp.data ? User.createFromJson(resp.data) : null))
    .catch(() => null)
}

export const updateUser = async (id: string, params: UserInput): Promise<User | null> => {
  const user = await api
    .put(`users/${id}`, params)
    .then((resp: any) => User.createFromJson(resp.data))
    .catch(() => null)

  return user
}

export const deleteUser = async (id: string): Promise<boolean> => {
  const result = await api
    .delete(`users/${id}`)
    .then(_ => true)
    .catch(() => false)

  return result
}

export const fetchUserGameFields = async (id: string): Promise<GameField[]> => {
  const fields = await api
    .get(`users/${id}/fields`)
    .then((resp: any) => (resp.data ?? []).map((it: any) => GameField.createFromJson(it)))
    .catch(() => [])

  return fields
}

export const fetchCommunityUserGameFields = async (
  id: string,
  communityId: string | null = null,
): Promise<GameField[]> => {
  const fields = await api
    .get(`members/${id}/fields/${communityId}`)
    .then((resp: any) => (resp.data ?? []).map((it: any) => GameField.createFromJson(it)))
    .catch(() => [])

  return fields
}

export const fetchUsers = async (page: number): Promise<{
  users: User[]
  total: number
  page: number
  limit: number
} | null> => {
  const users = await api
    .get(`admin/users/${page}`)
    .then((resp: any) => {
      const data = resp.data
      if (!data) return null
      return {
        users: data.users.map((it: any) => User.createFromJson(it)),
        total: data.total,
        page: data.page,
        limit: data.limit,
      }
    })
    .catch(() => null)

  return users
}

export const fetchUser = async (id: string | string[]): Promise<User | null> => {
  const user = await api
    .get(`admin/user/${id}`)
    .then((resp: any) => User.createFromJson(resp.data))
    .catch(() => null)

  return user
}
