import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';

// Eメールの形式かどうか
export function isValidEmail(value: string | null) {
  if (value == null) return false

  // Eメールアドレスの形式
  const pattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
  return pattern.test(value)
}

export const useNotify = () => {
  const $q = useQuasar()

  return (settings: {
    message: string
    caption?: string
    icon?: string
    color?: string
    position?:
      | 'top-left'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-right'
      | 'top'
      | 'bottom'
      | 'left'
      | 'right'
      | 'center'
    timeout?: number
  }) => {
    $q.notify({
      multiLine: true,
      html: true,
      message: settings.message,
      caption: settings.caption,
      icon: settings.icon,
      color: settings.color,
      position: settings.position ?? 'bottom',
      timeout: settings.timeout ?? 4000,
    })
  }
}

export const useSuccessNotify = () => {
  const $q = useQuasar()
  return (message: string, caption = '') => {
    $q.notify({
      multiLine: true,
      html: true,
      message,
      caption,
      icon: 'done',
      color: 'positive',
      position: 'bottom',
      timeout: 4000,
    })
  }
}

export const useErrorNotify = () => {
  const $q = useQuasar()
  return (error: Error) => {
    $q.notify({
      multiLine: true,
      html: true,
      message: error.name,
      caption: error.message,
      icon: 'report_problem',
      color: 'negative',
      position: 'bottom',
      timeout: 4000,
    })
  }
}

export const useInfoNotify = () => {
  const $q = useQuasar()
  return (message: string, caption = '') => {
    $q.notify({
      multiLine: true,
      html: true,
      message,
      caption,
      icon: 'done',
      color: 'info',
      position: 'top',
      timeout: 4000,
    })
  }
}
